
const ProductCategorySke = () => {
    const ItemSke = () => {
        return <div className="h-80 wrap-product-card cursor-pointer">
            <div className='w-full justify-center h-full product-card p-2 flex flex-col gap-1 md:gap-4'>
                <div className='w-52 h-52 product mx-auto flex justify-center items-center background-product animate-pulse' />
                <div className='h-4 bg-[#fafafa] animate-pulse w-1/2 mx-auto' />
            </div>
        </div>
    }
    return <div className='wrap-product-groups container mx-auto px-20 mb-12 w-full'>
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
        <ItemSke />
    </div>
}

export default ProductCategorySke;